.hp-logo {
  position: fixed;
  top: 30px;
  left: 0;
  right: 0;
  margin: auto;
  width: 250px;
}

.hp-login-container {
  width: 350px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  margin-top: 20px;
}

.hp-content {
  top: 40%;
  margin: auto;
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  font-family: "Source Code Pro", monospace;
}
.hp-body-container,
.hp-body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.content__heading {
  margin-bottom: 24px;
  color: #272727;
  font-size: 44px;
}

.hp-tagline {
  margin-bottom: 24px;
  color: #ccd2d6;
  font-size: 22px;
  width: 305px;
  left: 0;
  right: 0;
  margin: auto;
}

.hp-overlay {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  position: absolute;
}

.hp-field-margin-0 {
  margin-bottom: 0 !important;
}

.hp-forgot-pass-container {
  width: 350px;
  color: white;
  padding: 30px;
}
.hp-forgot-pass-container a {
  background: rgba(0, 0, 0, 0.5);
  padding: 5px 15px;
  margin: 0 5px;
  border-radius: 2px;
  color: #21ba45;
  cursor: pointer;
}

.hp-forgot-pass-container a:hover {
  color: #21ba45;
  opacity: 0.7;
}

.hp-login-container form .ui input[name="email"],
.hp-login-container form .ui input[name="email"]:focus {
  border-radius: 5px 5px 0 0;
}
.hp-login-container form .ui input[name="password"],
.hp-login-container form .ui input[name="password"]:focus {
  border-radius: 0 0 5px 5px;
}

.mediate-intro-video {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 1;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.login-form-field {
  margin-bottom: 0 !important;
}

.login-form-field .input input {
  font-family: "Source Code Pro", monospace !important;
}

.login-form-field .input input:focus {
  border: 1px solid #21ba45 !important;
}

.toggle-icon-label {
  font-family: "Source Code Pro", monospace !important;
}

.login-submit {
  margin-top: 20px !important;
  font-family: "Source Code Pro", monospace !important;
}

.login-error .header,
.signup-error .header {
  font-family: "Source Code Pro", monospace !important;
}

.login-error.show,
.signup-error.show {
  display: block;
}

.login-error.hide,
.signup-error.hide {
  display: none;
}

.toggle-icon-label {
  cursor: pointer;
}

/** ADMIN MENU **/

.three-menu-admin-container {
  display: flex;
  justify-content: flex-end;
  margin: 30px;
}

.three-menu-admin-trigger.icon {
  color: #21ba45 !important;
}

.three-menu-admin {
  font-family: "Source Code Pro", monospace !important;
  color: #fff !important;
  background: #21ba45 !important;
}

.three-menu-admin .item {
  color: #fff !important;
}

.three-menu-admin .item a {
  color: #fff;
  cursor: pointer;
}

/** Signup Modal **/
.three-admin-signup-modal {
  font-family: "Source Code Pro", monospace !important;
}

.three-admin-signup-modal .header {
  font-family: "Source Code Pro", monospace !important;
}

/** Accoint Settings **/
.three-admin-account-settings-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #1c1c1c;
  margin: 10%;
  font-family: "Source Code Pro", monospace !important;
  color: #fff;
}

.three-admin-account-settings-divider {
  color: #fff !important;
  font-family: "Source Code Pro", monospace !important;
}

/** Delete Account Component **/
.three-admin-delete-account-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.three-admin-delete-account-container h3 {
  font-family: "Source Code Pro", monospace !important;
}

/** Admin Confirm **/

.three-admin-confirm {
  font-family: "Source Code Pro", monospace !important;
}

.three-admin-confirm .button {
  font-family: "Source Code Pro", monospace !important;
}

.three-admin-confirm .primary.button {
  background-color: #21ba45 !important;
}

.three-admin-button {
  background-color: #21ba45 !important;
  color: #fff !important;
}

.three-admin-button-confirm {
  background-color: #de3b3b !important;
  color: #fff !important;
}

/** Verification **/
.three-admin-verify-user-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-family: "Source Code Pro", monospace !important;
}

.header {
  font-family: "Source Code Pro", monospace !important;
}

.three-admin-content-link {
  margin-top: 10px;
  border-top: 1px solid #ccc;
  padding-top: 5px;
}

.three-admin-home-link {
  color: inherit !important;
  font-weight: bold;
}

.three-admin-home-link:hover {
  opacity: 0.8;
}

/*** Famous' UI ***/

.admin-form-container {
  background: #000 !important;
}

.admin-views-list {
  background: #000 !important;
}

.admin-form-content {
  background: #000 !important;
  border: none !important;
}

.admin-main-form .header,
.admin-main-form .form > .field > label {
  color: #fff !important;
  font-family: "Source Code Pro", monospace;
}

.admin-menu {
  background: #000 !important;
}

.update-view-header-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.admin-list-label {
  background: none !important;
  color: #ccc !important;
  border: 1px solid #323232 !important;
}

.admin-upload-label {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  display: block !important;
}

.admin-file-upload-header {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  display: block !important;
}

.admin-list > .item {
  padding: 10px 0px 10px 0px !important;
  font-family: "Source Code Pro", monospace;
}

.admin-content-container {
  background: #000 !important;
}

.admin-content-container.loading {
  overflow: hidden;
  height: 100vh;
}

.admin-main-form-field {
  padding: 10px;
  border: 1px solid #323232;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}

.admin-select-text-input > .input input {
  background: none !important;
  border: 1px solid #323232 !important;
  font-family: "Source Code Pro", monospace !important;
  color: #ccc !important;
}

.admin-select-dropdown > .ui.selection.dropdown:hover {
  border: 1px solid #323232;
  color: #fff !important;
}

.admin-select-dropdown * .default.text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  display: block !important;
}

.admin-select-dropdown > .ui.selection.dropdown {
  background: none !important;
  border: 1px solid #323232;
  color: #ccc !important;
  font-family: "Source Code Pro", monospace;
}

.admin-select-dropdown * .menu.transition {
  background: #1b1b1b !important;
}

.admin-select-dropdown * .menu.transition > .item {
  color: #fff !important;
  border-top: 1px solid #323232 !important;
}

.admin-select-dropdown * .menu.transition > .item:hover {
  background: #323232 !important;
}

.admin-form-content * .button, .admin-form-content * .label {
  font-family: "Source Code Pro", monospace !important;
}

.admin-button-group, .admin-list-button-group {
  display: flex;
  flex-wrap: wrap;
}

/*** Admin Converter ***/
.admin-form-content * .three-converter-form {
  margin: 0% 2% 0% 2%;
  border: 2px solid #1b1b1b;
  border-radius: 0.3em;
  padding: 20px;
}

.three-converter-container-no-save {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.three-converter-restart-button {
  z-index: 1001;
  position: relative;
  background-color: #66da81 !important;
}

/****** ADMIN FORM **********/
.admin-form-header {
  color: #fff !important;
}
.admin-form-error-message .content .header {
  color: red !important;
}

.admin-form-error-message .content p {
  font-family: "Source Code Pro", monospace !important;
}

.admin-form-delete-modal > *, .admin-form-delete-modal .actions .button {
  font-family: "Source Code Pro", monospace !important;
}

.admin-form-delete-modal .actions .button.primary {
  background: red !important;
}

/*** Menu ***/
.admin-menu-link {
  color: #fff;
  font-family: "Source Code Pro", monospace !important;
}

.admin-menu-link:hover {
  color: #000;
}